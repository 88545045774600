import * as types from "./types";
import _ from "lodash";
import { currentNextDates } from "../../components/global/functions/customFormats";
const date = currentNextDates().current;
const initialState = {
  GrossSalary: 0.0,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_GROSS_SALARY:
      return { ...state, GrossSalary: action.payload };

    default:
      return state;
  }
};

// get current usage ids from apis and store in redux store
const initial_db_Ids = {
  EmployeeId: 0,
  EmployeesId: [],
  CompanyId: 0,
  CompanyIds: [],
  UserPermissionsIsSelectAll: true,
  UserPermissionsTasks: [],
  ManagerPermissionsTasks: [],
  UserPermissionsCompanies: [],
  UserPermissionsReports: [],
  IsEmployeesExist: false,
  EmployeeStatusId: 0,
  MasterId: 0,
  MasterName: "Master",
  MasterColumn: "Name",
  GratuityMasterId: 0,

  // UserId: 0,
  // ManagerId: 0,
  VehicleStatus: false,
  VehicleNameCode: "",
  VehicleId: 0,
  Date: date,
  RequestStatusId: 0,
  IsEmployeePersonalDetails: false,
  LoanId: 0,
  DependentId: 0,
  CommissionMasterId: 0,
};
export const db_Ids = (state = initial_db_Ids, action) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_ID:
      return { ...state, EmployeeId: action.payload };
    case types.SET_COMPANY_ID:
      return { ...state, CompanyId: action.payload };
    case types.SET_COMPANY_IDS:
      return { ...state, CompanyIds: action.payload };
    case types.SET_USER_PERMISSIONS_SELECT_ALL:
      return { ...state, UserPermissionsIsSelectAll: action.payload };
    case types.SET_USER_PERMISSIONS_TASKS:
      return { ...state, UserPermissionsTasks: action.payload };
    case types.SET_MANAGER_PERMISSIONS_TASKS:
      return { ...state, ManagerPermissionsTasks: action.payload };
    case types.SET_USER_PERMISSIONS_COMPANY:
      return { ...state, UserPermissionsCompanies: action.payload };
    case types.SET_USER_PERMISSIONS_REPORTS:
      return { ...state, UserPermissionsReports: action.payload };
    case types.SELECT_EMPLOYEES_ID_TO_PROCESS_PAYROLL:
      return { ...state, EmployeesId: action.payload };
    case types.IS_EMPLOYEES_EXIST:
      return { ...state, IsEmployeesExist: action.payload };
    case types.SET_EMPLOYEE_STATUS_ID:
      return { ...state, EmployeeStatusId: action.payload };
    case types.SET_MASTER_ID:
      return { ...state, MasterId: action.payload };
    case types.SET_MASTER_NAME:
      return { ...state, MasterName: action.payload };
    case types.SET_MASTER_COLUMN_NAME:
      return { ...state, MasterColumn: action.payload };
    case types.SET_GRATUITY_MASTER_ID:
      return { ...state, GratuityMasterId: action.payload };
    case types.SET_COMMISSION_MASTER_ID:
      return { ...state, CommissionMasterId: action.payload };
    case types.SET_USER_ID:
      return { ...state, UserId: action.payload };
    case types.SET_MANAGER_ID:
      return { ...state, ManagerId: action.payload };
    case types.SET_VEHICLE_STATUS:
      return { ...state, VehicleStatus: action.payload };
    case types.SET_VEHICLE_NAME_CODE:
      return { ...state, VehicleNameCode: action.payload };
    case types.SET_VEHICLE_ID:
      return { ...state, VehicleId: action.payload };
    case types.SET_DATE:
      return { ...state, Date: action.payload };
    case types.SET_REQUEST_STATUS:
      return { ...state, RequestStatusId: action.payload };
    case types.SET_IS_EMPLOYEE_PERSONAL_DETAILS:
      return { ...state, IsEmployeePersonalDetails: action.payload };
    case types.SET_LOAN_ID:
      return { ...state, LoanId: action.payload };
    case types.SET_DEPENDENT_ID:
      return { ...state, DependentId: action.payload };
    default:
      return state;
  }
};

const initial_ui_Ids = {
  AccordionIndexEmployee: 0,
  AccordionIndexCompany: 0,
  AccordionIndexSettings: 0,
  SelectedTabIndexCompanyBasicDetails: 0,
  SelectedTabIndexSettings: 0,
  SelectedTabIndexUsers: 0,
  SelectedTabIndexMiscSettings: 0,
  SelectedTabIndexGratuitySettings: 0,
  IsLoading: false,
  IsOpen: false,
  SelectedRequestAccordion: null,
  FromDate: "",
  ToDate: "",
  ShowFinalSettlementReport: false,
};
export const ui_Ids = (state = initial_ui_Ids, action) => {
  switch (action.type) {
    case types.ACCORDION_INDEX_EMPLOYEE:
      return { ...state, AccordionIndexEmployee: action.payload };
    case types.ACCORDION_INDEX_COMPANY:
      return { ...state, AccordionIndexCompany: action.payload };
    case types.ACCORDION_INDEX_SETTINGS:
      return { ...state, AccordionIndexSettings: action.payload };
    case types.TAB_INDEX_COMPANY_BASIC_DETAILS:
      return { ...state, SelectedTabIndexCompanyBasicDetails: action.payload };
    case types.TAB_INDEX_SETTINGS:
      return { ...state, SelectedTabIndexSettings: action.payload };
    case types.TAB_INDEX_USERS:
      return { ...state, SelectedTabIndexUsers: action.payload };
    case types.TAB_INDEX_MISC:
      return { ...state, SelectedTabIndexMiscSettings: action.payload };
    case types.TAB_INDEX_GRATUITY:
      return { ...state, SelectedTabIndexGratuitySettings: action.payload };
    case types.SET_IS_LOADING:
      return { ...state, IsLoading: action.payload };
    case types.SET_IS_OPEN_1:
      return { ...state, IsOpen: action.payload };
    case types.SET_SELECTED_ACCORDION_REQUEST:
      return { ...state, SelectedRequestAccordion: action.payload };
    case types.SET_FROM_DATE:
      return { ...state, FromDate: action.payload };
    case types.SET_TO_DATE:
      return { ...state, ToDate: action.payload };
    case types.SET_SET_FINAL_SETTLEMENT_REPORT:
      return { ...state, ShowFinalSettlementReport: action.payload };
    default:
      return state;
  }
};

const LOCAL_INITIAL_VALUES = {
  PayrollHeaderDate: "Month, Year",
  BatchName: 0,
  EmployeeNameCode: "",
  SelectTreeValue: undefined,
  SelectComparisonTreeValue: undefined,
  PayrollHeaderBatchesSelect: undefined,
  PayrollHeaderId: 0,
  PayrollId: 0,
  PayrollVoucherId: 0,
  PayrollHeaderComparisonId: 0,
  IsPayrollReleased: true,
  LoanShowSelect: 0,
  AssetShowSelect: "",
  MinutesLateRate: 0.0,
  WpsFileName: "",
  WpsExcelFileExtension: "",
  MasterFilterName: "",
  BankNameRoutingCode: "",
  MasterParameterSearchKey: "",
};

export const local_payroll_values = (state = LOCAL_INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.SET_PAYROLL_HEADER_DATE:
      return { ...state, PayrollHeaderDate: action.payload };
    case types.SET_PAYROLL_BATCH_NAME:
      return { ...state, BatchName: action.payload };
    case types.SET_EMPLOYEE_NAME_CODE:
      return { ...state, EmployeeNameCode: action.payload };
    case types.SET_MASTER_PARAMETER_SEARCH_KEY:
      return { ...state, MasterParameterSearchKey: action.payload };
    case types.SET_SELECT_TREE_VALUE:
      return { ...state, SelectTreeValue: action.payload };
    case types.SET_SELECT_COMPARISON_TREE_VALUE:
      return { ...state, SelectComparisonTreeValue: action.payload };
    case types.SET_PAYROLL_HEADER_BATCH_SELECT_VALUE:
      return { ...state, PayrollHeaderBatchesSelect: action.payload };
    case types.SET_PAYROLL_HEADER_ID:
      return { ...state, PayrollHeaderId: action.payload };
    case types.SET_PAYROLL_ID:
      return { ...state, PayrollId: action.payload };
    case types.SET_PAYROLL_VOUCHER_ID:
      return { ...state, PayrollVoucherId: action.payload };
    case types.SET_PAYROLL_HEADER_COMPARISON_ID:
      return { ...state, PayrollHeaderComparisonId: action.payload };
    case types.SET_PAYROLL_IS_RELEASED:
      return { ...state, IsPayrollReleased: action.payload };
    case types.SET_LOAN_SHOW_SELECT:
      return { ...state, LoanShowSelect: action.payload };
    case types.SET_ASSET_SHOW_SELECT:
      return { ...state, AssetShowSelect: action.payload };

    case types.SET_WPS_FILE_NAME:
      return { ...state, WpsFileName: action.payload };
    case types.SET_WPS_EXCEL_FILE_EXTENSION:
      return { ...state, WpsExcelFileExtension: action.payload };
    case types.SET_MASTER_FILTER_NAME:
      return { ...state, MasterFilterName: action.payload };
    case types.SET_BANK_NAME_ROUTING_CODE:
      return { ...state, BankNameRoutingCode: action.payload };
    default:
      return state;
  }
};
const ATTENDANCE_VALUES = {
  AttendanceMasterId: 0,
  EmployeeId: 0,
  AttendanceStatusId: 0,
};

export const local_attendance_values = (state = ATTENDANCE_VALUES, action) => {
  switch (action.type) {
    case types.SET_ATTENDANCE_MASTER_ID:
      return { ...state, AttendanceMasterId: action.payload };
    case types.SET_ATTENDANCE_EMPLOYEE_ID:
      return { ...state, EmployeeId: action.payload };
    case types.SET_ATTENDANCE_STATUS_ID:
      return { ...state, AttendanceStatusId: action.payload };
    default:
      return state;
  }
};
const ROSTER_VALUES = {
  RosterMasterId: 0,
  EmployeeId: 0,
  RosterStatusId: 0,
  RosterYear: 0,
  RosterMonth: 0,
};

export const local_roster_values = (state = ROSTER_VALUES, action) => {
  switch (action.type) {
    case types.SET_ROSTER_MASTER_ID:
      return { ...state, RosterMasterId: action.payload };
    case types.SET_ROSTER_EMPLOYEE_ID:
      return { ...state, EmployeeId: action.payload };
    case types.SET_ROSTER_STATUS_ID:
      return { ...state, RosterStatusId: action.payload };
    case types.SET_ROSTER_YEAR:
      return { ...state, RosterYear: action.payload };
    default:
      return state;
  }
};
export const EmployeeDDL = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYEES_DDL:
      return { ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
export const EmployeesStatuses = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYEES_STATUSES:
      return { ..._.mapKeys(action.payload, "Id") };
    default:
      return state;
  }
};
export const EmployeeExcelImports = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_EXCEL_IMPORTS:
      return { ..._.mapKeys(action.payload, "Id") };
    default:
      return state;
  }
};
